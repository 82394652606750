import { Route } from "react-router-dom";
import Viewer from "./components/Viewer";

import Utils from "./components/core/UtilsAndContexts";
import FourOhFour from "./layouts/404";

export default () => (
  <Utils>
    <Route exact path="/:name">
      <Viewer />
    </Route>
    <Route>
      <FourOhFour />
    </Route>
  </Utils>
);
