import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useEffect } from "react";

import { SetPageVariation } from "./Book";

const useStyles = makeStyles(
  ({
    palette: {
      grey,
      common: { white },
    },
    spacing,
    breakpoints: { down },
  }) => ({
    iconButton: {
      pointerEvents: "all",
      width: "5.6rem",
      height: "5.6rem",
      border: `solid 0.1rem ${grey[500]}`,
      backgroundColor: grey[200],
      opacity: 1,
      "&:hover, &:focus": {
        backgroundColor: white,
      },
      "&.Mui-disabled": {
        backgroundColor: grey[350],
        color: grey[600],
      },
      [down("md")]: {
        width: "4.4rem",
        height: "4.4rem",
      },
    },
    layout: {
      pointerEvents: "none",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "absolute",
      top: "0",
      right: spacing(3),
      bottom: "0",
      left: spacing(3),
    },
  })
);

export default ({
  setPageVariation,
  disabledVariations,
}: {
  setPageVariation: SetPageVariation;
  disabledVariations: {
    decrement: boolean;
    increment: boolean;
  };
}) => {
  const { iconButton, layout } = useStyles();

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft" && !disabledVariations.decrement) {
        setPageVariation("decrement");
      }

      if (event.key === "ArrowRight" && !disabledVariations.increment) {
        setPageVariation("increment");
      }
    };

    document.addEventListener("keydown", handler);

    return () => document.removeEventListener("keydown", handler);
  }, [
    disabledVariations.decrement,
    disabledVariations.increment,
    setPageVariation,
  ]);

  return (
    <div className={layout}>
      <IconButton
        className={iconButton}
        disabled={disabledVariations.decrement}
        onClick={() => setPageVariation("decrement")}
      >
        <ChevronLeftIcon
          color={disabledVariations.decrement ? "inherit" : "primary"}
          viewBox="4.3 4.3 14.4 14.4"
        />
      </IconButton>
      <IconButton
        className={iconButton}
        disabled={disabledVariations.increment}
        onClick={() => setPageVariation("increment")}
      >
        <ChevronRightIcon
          color={disabledVariations.increment ? "inherit" : "primary"}
          viewBox="4.3 4.3 14.4 14.4"
        />
      </IconButton>
    </div>
  );
};
