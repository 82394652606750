import { useEffect, useMemo, useState } from "react";
import { animated, useSpring, to } from "react-spring";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  left: {
    transformOrigin: "right center",
  },
  right: {
    transformOrigin: "left center",
  },
  pageGroup: {
    perspective: "4000px",
    position: "absolute",
    display: "flex",
  },
});

export default ({
  loaded,
  children,
  absoluteIndex,
}: {
  loaded: boolean;
  children: (JSX.Element | JSX.Element[])[];
  absoluteIndex: number;
}) => {
  const { left, right, pageGroup } = useStyles();
  const [prevPage, setPrevPage] = useState(0);
  const direction = useMemo(
    () => absoluteIndex - prevPage,
    [absoluteIndex, prevPage]
  );

  const [style, api] = useSpring(() => ({
    from: {
      display: 0,
    },
    config: {
      tension: 1000,
      friction: 100,
    },
  }));

  useEffect(() => {
    if (!(Math.abs(direction) in [0, 1])) {
      setPrevPage(absoluteIndex);

      return;
    }

    if (absoluteIndex !== prevPage) {
      let cancelled = false;

      api.start({ display: 1 })[0].then(() => {
        if (!cancelled) {
          setPrevPage(absoluteIndex);
          api.set({ display: 0 });
        }
      });

      return () => {
        cancelled = true;

        api.stop();
        setPrevPage(absoluteIndex);
        api.set({ display: 0 });
      };
    }
  }, [api, absoluteIndex, children, direction, prevPage]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {children.map((child, index) => {
        const animatedIndex = prevPage;
        const isCurrentPage = index === animatedIndex;
        const currentPageDouble = Array.isArray(child) && child.length === 2
        const isLeftPage = index === animatedIndex - 1;
        const isRightPage = index === animatedIndex + 1;
        const goingRight = direction > 0;
        const goingLeft = direction < 0;
        const leftSibling = children[index - 1];
        const rightSibling = children[index + 1];
        const leftSiblingDouble = Array.isArray(leftSibling) && leftSibling.length === 2
        const rightSiblingDouble = Array.isArray(rightSibling) && rightSibling.length === 2

        const origin = !currentPageDouble
          ? goingRight
            ? leftSibling && leftSiblingDouble
              ? "right"
              : "left"
            : goingLeft
            ? rightSibling && rightSiblingDouble
              ? "left"
              : "right"
            : undefined
          : undefined;

        const leftRotate =
          (isCurrentPage && goingLeft) || (isRightPage && goingRight) || index > animatedIndex ?
            to([style.display], (display) => {
              return isCurrentPage && goingLeft ? display * 180 :
                isRightPage && goingRight ? 180 - display * 180 :
                180;
            }) :
          null

        const rightRotate =
          (isCurrentPage && goingRight) || (isLeftPage && goingLeft) || index < animatedIndex ?
            to([style.display], (display) => {
              return isCurrentPage && goingRight ? 360 - display * 180 :
                isLeftPage && goingLeft ? 180 + display * 180 :
                180
            }) :
          null

        const groupStyle = {
          perspectiveOrigin: origin,
          ...(!currentPageDouble
            ? {
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }
            : {}),
          zIndex: to([style.display], (display) => {
            if (isCurrentPage) {
              if (prevPage === absoluteIndex) {
                return 2;
              }

              if (display > 0.5) {
                return 1;
              }

              if (display < 0.5) {
                return 2;
              }
            }

            if (isLeftPage && goingLeft) {
              if (display > 0.5) {
                return 2;
              }

              return 1;
            }

            if (isRightPage && goingRight) {
              if (display > 0.5) {
                return 2;
              }

              return 1;
            }

            return 0;
          }),
          translateX: to([style.display], (display) => {
            if (!currentPageDouble) {
              if (goingRight) {
                if (leftSibling && leftSiblingDouble) {
                  return -50 + 50 * display + "%";
                }

                if (rightSibling && rightSiblingDouble) {
                  return 50 * display + "%";
                }
              }
              if (goingLeft) {
                if (leftSibling && leftSiblingDouble) {
                  return -50 * display + "%";
                }

                if (rightSibling && rightSiblingDouble) {
                  return 50 - 50 * display + "%";
                }
              }

              return 0;
            }

            if (!leftSiblingDouble) {
              if (goingRight && isRightPage) {
                return -25 + 25 * display + "%";
              }

              if (goingLeft && isCurrentPage) {
                return -25 * display + "%";
              }
            }

            if (!rightSiblingDouble) {
              if (goingRight && isCurrentPage) {
                return 25 * display + "%";
              }

              if (goingLeft && isLeftPage) {
                return 25 - 25 * display + "%";
              }
            }

            return 0;
          }),
          display:
            isCurrentPage ||
            (isRightPage && goingRight) ||
            (isLeftPage && goingLeft)
              ? undefined
              : "none",
        };

        return (
          <animated.div
            key={index}
            className={pageGroup}
            style={{ ...groupStyle, opacity: loaded ? undefined : 0 }}
          >
            {currentPageDouble ? (
              <>
                <animated.div className={left} style={ leftRotate ? { rotateY: leftRotate } : {}}>
                  {(child as JSX.Element[])[0]}
                </animated.div>
                <animated.div
                  className={right}
                  style={ rightRotate ? { rotateY: rightRotate } : {}}
                >
                  {(child as JSX.Element[])[1]}
                </animated.div>
              </>
            ) : (
              <animated.div
                style={{
                  transformOrigin: origin,
                  ...(index === 0 && rightRotate ? { rotateY: rightRotate } : {}),
                  ...(index !== 0 && leftRotate ? { rotateY: leftRotate } : {}),
                }}
              >
                {child}
              </animated.div>
            )}
          </animated.div>
        );
      })}
    </div>
  );
};
