import fscreen from "fscreen";
import fetch from 'node-fetch'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import FourOhFour from '../layouts/404'
import Standard from "../layouts/Standard";
import Loading from '../layouts/Loading'
import Book from "./Book";

export default () => {
  const { name } = useParams<{ name: string }>()
  const url = '/files/' + name + '.pdf'

  const [ state, setState ] = useState<'loading' | 'loaded' | 'error'>('loading')
  const [fullscreen, setFullscreen] = useState(false);

  useEffect(
    () => { fetch(url, { method: 'head' }).then((res) => setState(res.ok ? 'loaded' : 'error')) },
    [ url ]
  )

  useLayoutEffect(() => {
    if (document.readyState === "complete") {
      if (fullscreen) {
        fscreen.requestFullscreen(document.body);
      }

      if (fscreen.fullscreenElement && !fullscreen) {
        fscreen.exitFullscreen();
      }

      const handler = () => {
        if (!fscreen.fullscreenElement && fullscreen) {
          setFullscreen(false);
        }
      };

      fscreen.addEventListener("fullscreenchange", handler, { passive: true });

      return () =>
        fscreen.removeEventListener("fullscreenchange", handler, {
          passive: true,
        });
    }
  }, [fullscreen]);

  const loadingEl = Loading()
  const fourOhFourEl = FourOhFour()

  if (state === 'loading') return loadingEl
  if (state === 'error') return fourOhFourEl

  return (
      <Standard>
        <Book {...{ fullscreen, setFullscreen, url }} />
      </Standard>
    );
};
