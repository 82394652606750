import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Standard from "./Standard";

export default () => {
  return (
    <Standard>
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <div>
          <Typography variant="h1">Page not found</Typography>
        </div>
        <Box marginTop={3}>
          <Typography variant="body1">
            This content has moved or is no longer available.
          </Typography>
        </Box>
      </Box>
    </Standard>
  );
};
