import { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

import FiveHundred from "../../layouts/500";
import { useGa } from "../../utils/WithGA";

const FallbackComponent = () => (
  <FiveHundred />
);

export default ({ children }: { children: ReactNode[] | ReactNode }) => {
  const ga = useGa();

  return (
    <ErrorBoundary
      {...{ FallbackComponent }}
      onError={({ message }) => {
        ga("event", "exception", {
          description: message,
          fatal: true,
        });
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
