import { useEffect } from 'react'
import ga from 'react-ga'

const ua = process.env.REACT_APP_GA_UA
const isProd = process.env.NODE_ENV === 'production'

export default () => {

  useEffect(() => {
    if (!ua) return

    ga.initialize(ua, { debug: !isProd, testMode: !isProd })
  }, [])

  return null
}

export const event = (category: string, action: string, label?: string, value?: number) => {
  if (!ua) return

  ga.event({ category, action, label, value })
}
