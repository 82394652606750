import "./index.css";
import "@slqweb/components/css/main.css";
import "@slqweb/react-pdf/dist/esm/Page/AnnotationLayer.css";

import { pdfjs } from "@slqweb/react-pdf";
// import pdfjs from "pdfjs-dist";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

/* eslint-disable import/first */
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
/* eslint-enable import/first */

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
