import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Button from "@slqweb/components/Button";

import Standard from "./Standard";

export default () => (
  <Standard>
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <div>
        <Typography variant="h1">Something went wrong…</Typography>
      </div>
      <Box marginTop={3}>
        <Typography variant="body1">
          Sorry, we are working on fixing the problem.
        </Typography>
      </Box>
      <Box marginTop={6}>
        <Button onClick={() => window.location.reload()}>Refresh page</Button>
      </Box>
    </Box>
  </Standard>
);
