import {
  // useEffect,
  ReactNode,
  // useState,
  // createContext,
  // useContext,
  // useRef,
} from "react";
// import GA4React from "ga-4-react";

// type Event = [string, string, { [key: string]: any }];

// const analyticsContext = createContext<
//   (action: string, type: string, parameters: { [key: string]: any }) => void
// >(() => {});

// export const WithGA = ({ children }: { children: ReactNode | ReactNode[] }) => {
//   const [events, setEvents] = useState<Event[]>([]);
//   const { current: ga4react } = useRef(
//     new GA4React(
//       window.location.host === "dev-54ta5gq-glrrctfhs4cvi.au.platformsh.site"
//         ? process.env.GOOGLE_ANALYTICS_ID_DEVELOPMENT
//         : window.location.host === "curriculumconnect.slq.qld.gov.au"
//         ? process.env.GOOGLE_ANALYTICS_ID_PRODUCTION
//         : process.env.GOOGLE_ANALYTICS_ID_LOCAL,
//       { debug_mode: process.env.NODE_ENV !== "production" },
//     )
//   );

//   useEffect(() => {
//     if (!GA4React.isInitialized()) {
//       ga4react.initialize().catch();
//     }
//   }, [ga4react]);

//   useEffect(() => {
//     // If we have some events and gtag is ready
//     if (GA4React.isInitialized() && ga4react?.gtag && events.length > 0) {
//       const eventsToSend = events;

//       setEvents([]);

//       for (const event of eventsToSend) {
//         ga4react.gtag(...event);
//       }
//     }
//   }, [ga4react, events]);

//   return (
//     <analyticsContext.Provider
//       value={(action, type, parameters) =>
//         setEvents((oldEvents) => [...oldEvents, [action, type, parameters]])
//       }
//     >
//       <>
//         {children}
//       </>
//     </analyticsContext.Provider>
//   );
// };

// export const useGa = () => useContext(analyticsContext);
// export const useGaShare = (type: "List" | "Resource") => {
//   const ga = useGa();

//   return (platform: string, id: number) => {
//     ga("event", "share", {
//       method: platform,
//       content_type: type,
//       resource_id: id,
//     });
//   };
// };

// Remove when ready
export const useGa = () => (..._args: any[]) => {};
export const useGaShare = () => (..._args: any[]) => {};
export const WithGA = ({ children }: { children: ReactNode | ReactNode[] }) => (
  <>{children}</>
);
