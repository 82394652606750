import Box from "@material-ui/core/Box";
import { forwardRef, ReactNode } from "react";

export default forwardRef<
  HTMLDivElement,
  { children: ReactNode | ReactNode[] }
>(({ children }, ref) => (
  <Box
    {...{ ref }}
    width="100vw"
    maxHeight="100vh"
    minHeight={0}
    display="flex"
    flexDirection="column"
    // height="calc((3 / 4) * 100vw)"
    height="100vh"
  >
    {children}
  </Box>
));
