import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Standard from "./Standard";

const useStyles = makeStyles(({ palette: { grey } }) => ({
  spinner: {
    color: grey[600],
  },
}));

export default () => {
  const { spinner } = useStyles();

  return (
    <Standard>
      <CircularProgress className={spinner} color="inherit" />
    </Standard>
  );
};
