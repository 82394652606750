import { useState, useCallback, useEffect } from "react";

import { InputPackets, OutputPackets } from "./search.worker";

export interface FlattenedWord {
  id: string;
  string: string;
  pageIndex: number;
  wordIndex: number;
}

const searchWorker = new Worker("./search.worker.ts", { type: "module" });
const sendMessage = (packet: InputPackets) => searchWorker.postMessage(packet);

export default (flattenedWords: FlattenedWord[]) => {
  const [ready, setReady] = useState(false);
  const [results, setResults] = useState<FlattenedWord[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!ready && flattenedWords.length > 0) {
      sendMessage({
        type: "initialise",
        data: flattenedWords,
      });
    }
  }, [ready, flattenedWords]);

  const search = useCallback((needle: string) => {
    setLoading(true);

    sendMessage({
      type: "search",
      data: needle,
    });
  }, []);

  useEffect(() => {
    const handler = ({ data }: MessageEvent<OutputPackets>) => {
      if (data.type === "ready") {
        setReady(data.data);
      }

      if (data.type === "searchResults") {
        setLoading(false);
        setResults(data.data);
      }
    };

    searchWorker.addEventListener("message", handler);

    return () => searchWorker.removeEventListener("message", handler);
  }, []);

  return [ready, results, loading, search] as [
    typeof ready,
    typeof results,
    typeof loading,
    typeof search
  ];
};
