import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Button from "@slqweb/components/Button";
import Link from "@slqweb/components/Link";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";

import CloseIcon from "@material-ui/icons/Close";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";

import { useSpring, animated } from "react-spring";
import { useResizeDetector } from "react-resize-detector";
import { useDebouncedCallback } from "use-debounce/lib";

import useSearch, { FlattenedWord } from "../utils/search/useSearch";

const pageDepth = 25;

const useStyles = makeStyles(
  ({ spacing, palette: { grey }, breakpoints: { down }, overrides }) => ({
    resultRow: {
      padding: spacing(1, 2),
      borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
      display: "flex",
      "&:hover": {
        backgroundColor: grey[50],
      },
    },
    loadMore: {
      padding: spacing(1, 2),
      borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "6.15rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: grey[50],
        "& > .MuiLink-root": {
          textDecorationColor: "inherit",
        },
      },
    },
    resultTypography: {
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    pageNumber: {
      margin: spacing(0, 0, 0, 2),
    },
    mainLayout: {
      position: "absolute",
      margin: spacing(3),
      width: `calc(100vw - ${spacing(3)}px * 2)`,
      maxWidth: "36rem",
      top: 0,
      right: 0,
      pointerEvents: "none",
      display: "flex",
      flexDirection: "column",
      [down("md")]: {
        maxWidth: "unset",
        margin: spacing(2),
        width: `calc(100vw - ${spacing(2)}px * 2)`,
      },
    },
    animatedDiv: {
      margin: "0 0 0 auto",
      pointerEvents: "auto",
      display: "flex",
      flexDirection: "row",
      zIndex: 1,
    },
    inputLayout: {
      flex: "1 0 0px",
      background: "white",
      minWidth: 0,
      position: "relative",
      overflow: "hidden",
    },
    textField: { width: "100%", height: "100%" },
    endAdornment: {
      width: "4.8rem",
    },
    searchButton: {
      minWidth: "4.8rem",
      padding: "0.7rem 1rem",
      height: "4.8rem",
    },
    resultsPaper: {
      pointerEvents: "auto",
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
      overflow: "hidden",
      zIndex: 0,
    },
    resultCount: { height: "5rem" },
    resultCountTypography: { padding: "1.6rem" },
    resultsLayout: { maxHeight: '40rem', overflowY: 'auto' },
  })
);

export const sliceWord = (needle: string, string: string) => {
  const escaped = needle.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  const match = new RegExp(`\\b${escaped}\\w*`, "i").exec(string)?.[0] ?? ''
  const [left, right] = string.split(match);

  return { left, match, right }
};

export type ResultState = {
  needle: string;
  results: FlattenedWord[];
} | null;

export default ({
  flattenedWords,
  setPageIndex,
  setSearchResults,
  getRelativeFromAbsolute,
}: {
  flattenedWords: FlattenedWord[];
  setPageIndex: (pageIndex: number) => void;
  setSearchResults: (searchResults: ResultState) => void;
  getRelativeFromAbsolute: (pageIndex: number) => number;
}) => {
  const [ready, absResults, loading, search] = useSearch(flattenedWords)
  const results = useMemo(
    () => absResults.map(r => {
      const index = getRelativeFromAbsolute(r.pageIndex)
      return {
        ...r,
        id: index + '_' + r.wordIndex,
        pageIndex: index,
      }
    }),
    [ absResults, getRelativeFromAbsolute ]
  )
  const flattenedWordsMap = useMemo(
    () => new Map(flattenedWords.map(({ id }, index) => [id, index])),
    [flattenedWords]
  );

  const {
    resultRow,
    resultTypography,
    pageNumber,
    mainLayout,
    animatedDiv,
    inputLayout,
    textField,
    endAdornment,
    searchButton,
    resultsPaper,
    resultCount,
    resultCountTypography,
    resultsLayout,
    loadMore,
  } = useStyles();

  const [page, setPage] = useState(1);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const [needle, setNeedle] = useState("");
  const [uiShouldOpen, setUiShouldOpen] = useState(false);
  const canvasContext = useMemo(() => {
    const canvasContext = document
      .createElement("canvas")
      .getContext("2d") as CanvasRenderingContext2D;

    canvasContext.font = "16px GothamNarrowSSm";

    return canvasContext;
  }, []);

  const mainDivRef = useRef<HTMLDivElement>(null);

  const [style, api] = useSpring(() => ({
    from: {
      width: 48,
    },
    config: {
      tension: 1000,
      friction: 75,
    },
  }));

  const debouncedSearch = useDebouncedCallback(
    (inputNeedle) => search(inputNeedle),
    400,
    {
      trailing: true,
    }
  );

  useEffect(() => debouncedSearch(needle), [needle, debouncedSearch]);

  const reset = useCallback(() => {
    setNeedle("");
    search("");
    setPage(1);
  }, [search]);

  const close = useCallback(() => {
    reset();
    setUiShouldOpen(false);
  }, [reset]);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        close();
      }
    };

    document.addEventListener("keydown", handler);

    return () => document.removeEventListener("keydown", handler);
  }, [close]);

  // Handle animation
  useEffect(() => {
    if (
      uiShouldOpen &&
      ready &&
      style.width.get() < (mainDivRef.current?.clientWidth ?? 0)
    ) {
      textFieldRef.current?.focus();
      api.start({ width: mainDivRef.current?.clientWidth ?? 0 });
    }

    if (!uiShouldOpen) {
      api.start({ width: 48 });
    }
  }, [uiShouldOpen, api, needle, ready, style, close]);

  // Handle click out
  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!mainDivRef.current?.contains(event.target as Node)) {
        close();
      }
    };

    document.addEventListener("click", handler);

    return () => document.removeEventListener("click", handler);
  }, [search, close]);

  // Handle search results
  useEffect(() => {
    if (needle !== "" && results.length > 0) {
      setSearchResults({
        needle,
        results,
      });
    }

    if (results.length === 0) {
      setSearchResults(null);
    }
  }, [needle, results, setSearchResults]);

  const { width: targetWidth = 0 } = useResizeDetector({
    targetRef: mainDivRef,
  });

  const loadMoreNumber = useMemo(
    () =>
      results.length - page * pageDepth > pageDepth
        ? pageDepth
        : results.length - page * pageDepth,
    [page, results]
  );

  return (
    <div className={mainLayout} ref={mainDivRef}>
      <animated.div className={animatedDiv} {...{ style }}>
        <div className={inputLayout}>
          <TextField
            inputRef={textFieldRef}
            value={needle}
            onChange={(event) =>
              setNeedle((event.target as HTMLInputElement).value)
            }
            inputProps={{
              placeholder: "Search in this book",
            }}
            className={textField}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={reset}
                  className={endAdornment}
                  style={{
                    visibility: needle === "" ? "hidden" : undefined,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              ),
            }}
          />
        </div>
        <Button
          variant="outlined"
          color="secondary"
          loading={
            !ready || needle === ""
              ? false
              : loading || debouncedSearch.isPending()
          }
          aria-label="Search"
          onClick={() => {
            if (needle === "") {
              if (uiShouldOpen) {
                close();
              } else {
                setUiShouldOpen(true);
              }
            }
          }}
          disabled={!ready}
          className={searchButton}
          tabIndex={0}
        >
          <SearchOutlinedIcon />
        </Button>
      </animated.div>
      {ready && !loading && !debouncedSearch.isPending() && needle !== "" && (
        <Paper className={resultsPaper}>
          <div className={resultCount}>
            <Typography
              className={resultCountTypography}
              ref={textRef}
              variant="body2"
            >
              {results.length === 0 ? "No" : results.length} result
              {results.length === 1 ? "" : "s"} found
            </Typography>
          </div>
          <div className={resultsLayout}>
            {results.slice(0, page * pageDepth).map((result, key) => {
              const { left, match, right } = sliceWord(needle, result.string)

              const index = flattenedWordsMap.get(result.id) ?? 0;

              canvasContext.font = "bold 16px GothamNarrowSSm";
              const matchWidth = canvasContext.measureText(match + " ").width;
              canvasContext.font = "16px GothamNarrowSSm";

              const pageNumberWidth =
                canvasContext.measureText(result.pageIndex + 1 + "").width + 16;

              const remainingWidth =
                (targetWidth - pageNumberWidth) * 2 - matchWidth;

              const leftWords: string[] =
                left?.split(" ").reduceRight<string[]>((acc, cur) => {
                  if (acc.length >= 3) {
                    return acc;
                  }

                  if (
                    canvasContext.measureText(["...", ...acc, cur].join(" "))
                      .width <
                    remainingWidth / 2
                  ) {
                    return [cur, ...acc];
                  }

                  return acc;
                }, []) ?? [];

              const rightWords: string[] =
                right?.split(" ").reduce<string[]>((acc, cur) => {
                  if (
                    canvasContext.measureText(
                      [...leftWords, "...", ...acc, cur].join(" ")
                    ).width < remainingWidth
                  ) {
                    return [...acc, cur];
                  }

                  return acc;
                }, []) ?? [];

              const moreLeftWords = flattenedWords
                .slice(index - 3, index)
                .map(({ string }) => string)
                .join(" ")
                .split(" ")
                .reduceRight<string[]>((acc, cur) => {
                  if (leftWords.length + acc.length >= 3) {
                    return acc;
                  }

                  if (
                    canvasContext.measureText(
                      [...leftWords, ...rightWords, "...", ...acc, cur].join(
                        " "
                      )
                    ).width <
                    remainingWidth / 2
                  ) {
                    return [cur, ...acc];
                  }

                  return acc;
                }, []);

              const moreRightWords = flattenedWords
                .slice(index + 1, index + 10)
                .map(({ string }) => string)
                .join(" ");

              return (
                <div
                  className={resultRow}
                  {...{ key }}
                  onClick={ () => setPageIndex(result.pageIndex) }
                >
                  <Typography variant="body1" className={resultTypography}>
                    ...{[ ...moreLeftWords, ...leftWords ].join(" ").trim() }
                    { left.length > 0 && left.match(/\s$/) ? ' ' : '' }
                    <b>{match.trim()}</b>
                    { right.length > 0 && right.match(/^\s/) ? ' ' : '' }
                    {[...rightWords].join(" ").trim()} {moreRightWords}...
                  </Typography>
                  <Typography className={pageNumber}>
                    { result.pageIndex + 1 }
                  </Typography>
                </div>
              );
            })}
            {
              <div
                className={loadMore}
                onClick={() => setPage((page) => page + 1)}
                style={{
                  display:
                    page * pageDepth < results.length ? undefined : "none",
                }}
              >
                <Link>
                  Show +{loadMoreNumber} result{loadMoreNumber === 1 ? "" : "s"}
                </Link>
              </div>
            }
          </div>
        </Paper>
      )}
    </div>
  );
};
