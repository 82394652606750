import "@slqweb/components/themes/index";

import { ReactNode, Suspense } from "react";
import { BrowserRouter, Switch } from "react-router-dom";

import ComponentContext from "@slqweb/components/utils/ComponentContext";
import CssBaseline from "@material-ui/core/CssBaseline";

import Loading from "../../layouts/Loading";
import ErrorBoundary from "./ErrorBoundary";
import Analytics from '../../utils/analytics';

export default ({ children }: { children: ReactNode[] | ReactNode }) => (
  <ComponentContext themeName="main">
    <BrowserRouter>
      <ErrorBoundary>
        <CssBaseline />
        <Analytics />
        <Suspense fallback={<Loading />}>
          <Switch>{children}</Switch>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  </ComponentContext>
);
